.SliderDiv {
    background-color: #000;
    height: 400px;
}

.sliderOverlayText {
    font-family: Montserrat, sans-serif;
    letter-spacing: 2.5px; 
    color: #FFF; 
    text-transform: 'uppercase'; 
    font-size: '32px'; 
    z-index: 11; 
    position: absolute; 
    bottom: 32; 
    left:64;
}

@media only screen and (max-width: 600px) {
    .sliderOverlayText {
        font-size: 24;
    }
}