.breadcrumbRow {
    padding: 60px 0;
    background-color: #F5F5F5;
    color: white;
    background: linear-gradient(to right, #0063a8, #0081bc, #249ecc, #4ebbda, #78d8e7);
}

.imageBreadcrumbText {
    position: absolute; 
    right:128px; 
    bottom: 64px; 
    color: white; 
    font-size: 64px;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    
}

@media screen and (max-width: 1024px) {
    .imageBreadcrumbText {
        bottom: 40px; 
    }
}

@media screen and (max-width: 768px) {
    .imageBreadcrumbText {
        font-size: 40px;
    }
}

@media screen and (max-width: 425px) {
    .imageBreadcrumbText {
        font-size: 32px;
        right: 64px;
        bottom: 8px;
    }
}

